import RegisterBase from './RegisterBase'
import SignUpForm from '@/components/signup/SignUpForm.vue'
import SignUpServerErrors from '@/components/signup/SignUpServerErrors.vue'
import SignUpSuccessMessage from '@/components/signup/SignUpSuccessMessage.vue'
import { isPasswordStrongEnough } from '@hypefactors/shared/js/utils/validation'

import { required, email } from 'vuelidate/lib/validators'

/**
 * Default functionality for the free newsroom register landing pages.
 * Used both on {@see module:FreeNewsroomLanding} and {@see module:FreeNewsroomLanding2}
 * @module FreeNewsroomLandingBase
 */
export default {
  components: {
    SignUpServerErrors,
    SignUpSuccessMessage,
    SignUpForm
  },

  mixins: [RegisterBase],

  validations: {
    formFields: {
      email: { required, 'email_ext.invalid': email },
      password: { required, 'password.strength': isPasswordStrongEnough(6) }
    }
  },

  data () {
    return {
      bullets: [...Array(3).keys()],
      steps: [...Array(4).keys()],
      mounted: false
    }
  },

  computed: {
    pageTitle () {
      switch (this.$route.query.from_ref) {
        case 'localnewz':
          return 'LocalNews.biz and Hype.News are pleased to announce our new affiliation. This means that you can create your own newsroom and publish your press releases for free to the Hype.News platform.'
        default:
          return this.$t('pages.free_newsroom.distribute_press')
      }
    },
    pageSubtitle () {
      switch (this.$route.query.from_ref) {
        case 'localnewz':
          return 'Create your free newsroom here:'
        default:
          return ''
      }
    },
    pageFineprint () {
      switch (this.$route.query.from_ref) {
        case 'localnewz':
          return 'If you have any questions or need help creating your newsroom, feel free to contact us at <a href="mailto:info@hypefactors.com">info@hypefactors.com</a>'
      }
    }
  },

  mounted () {
    this.prefill()
    this.getUsersIP()
    this.mounted = true
  }
}
