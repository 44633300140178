<template>
  <form
    class="SignUpForm"
    @submit.prevent="submitRegister"
  >
    <div
      :class="{'is-column': !isHorizontal}"
      class="columns"
    >
      <div class="column">
        <form-field
          :label="$t('forms.company_email')"
          :validator="v.email"
          hide-label
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator }"
            :placeholder="$t('forms.enter_company_email')"
            type="email"
            class="input"
            tabindex="0"
            data-testid="SignUpForm-Email"
          >
        </form-field>
      </div>
      <div class="column">
        <form-field
          :label="$t('forms.create_password')"
          :validator="v.password"
          hide-label
        >
          <input
            v-model="validator.$model"
            slot-scope="{ validator }"
            :placeholder="$t('forms.type_password')"
            type="password"
            class="input"
            tabindex="0"
            data-testid="SignUpForm-Password"
          >
        </form-field>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <div class="control has-text-right">
            <v-button
              :loading="isLoading"
              type="submit"
              class="is-primary is-large"
              data-testid="submit"
              @click="sendGAClickEvent(gaEvent)"
            >
              {{ $t('forms.register') }}
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  model: {
    prop: 'formFields',
    event: 'input'
  },

  props: {
    gaEvent: { type: String, default: 'hypenews signup animated' },
    submitRegister: { type: Function, default: () => {} },
    formFields: { type: Object, required: true },

    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },

    v: {
      type: Object,
      required: true
    },

    isHorizontal: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    sendGAClickEvent (event) {
      this.$ma.trackEvent({
        category: 'button',
        action: 'click',
        label: event
      })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.SignUpForm {
  &--withWhiteErrors {
    .field .help {
      color: white;
    }
  }
}
</style>
