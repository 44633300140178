<template>
  <div class="FreeNewsroomLanding2">
    <section
      class="FreeNewsroomLanding2__section1 hero is-large is-cover-image"
      data-testid="primarySignupSection"
    >
      <div class="hero-body">
        <div class="container">
          <div class="has-text-centered">
            <heading
              size="1"
              color="white"
            >
              {{ $t(translationPrefix + '.section1.title') }}
            </heading>

            <div class="is-width-2/5 m-h-a m-b-m">
              <div class="has-text-white is-size-4 has-text-serif">
                {{ $t(translationPrefix + '.section1.text') }}
              </div>
            </div>
          </div>

          <div
            v-if="!successful"
            class="columns is-centered"
          >
            <div class="column is-10 is-9-desktop is-7-fullhd">
              <sign-up-form
                v-model="formFields"
                :submit-register="submitRegister"
                :is-loading="isLoading"
                :v="$v.formFields"
                is-horizontal
                class="m-b-l SignUpForm--withWhiteErrors"
              />
            </div>
          </div>

          <sign-up-success-message
            v-if="successful"
            key="message"
            class="is-size-6 m-b-xxl"
          />

          <div class="has-text-weight-bold" v-html="pageFineprint" />

          <sign-up-server-errors :server-errors="serverErrors" />
        </div>
      </div>
    </section>

    <section class="FreeNewsroomLanding2__section2 hero is-white is-medium">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-aligned-middle">
            <div
              :class="{ fadeInUp: mounted }"
              class="column animated"
            >
              <img
                :src="$asset('/images/landing2/computer.png')"
                class="FreeNewsroomLanding2__floating-image"
              >
            </div>
            <div class="column">
              <heading size="1">
                {{ $t(translationPrefix + '.section2.title') }}
              </heading>

              <div class="is-size-5">
                <p>{{ $t(translationPrefix + '.section2.text') }}</p>

                <ul class="p-l-l styled-list styled-list--bullet styled-list--distanced p-t-l">
                  <li
                    v-for="n in [1, 2, 3, 4]"
                    :key="n"
                    class="is-flex is-aligned-middle"
                  >
                    {{ $t(translationPrefix + '.section2.bullets.' + n) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="FreeNewsroomLanding2__section3 hero is-large is-cover-image">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6">
              <heading size="1">
                {{ $t(translationPrefix + '.section3.title') }}
              </heading>

              <p class="is-size-4">
                {{ $t(translationPrefix + '.section3.text') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="FreeNewsroomLanding2__section6 hero is-medium is-white">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-aligned-middle">
            <div
              :class="{ fadeInUp: mounted }"
              class="column is-flex is-aligned-middle is-aligned-center animated"
            >
              <img
                :src="$asset('/images/for_brands/amazing_press_releases.png')"
                class="FreeNewsroomLanding2__floating-image"
              >
            </div>
            <div class="column">
              <heading size="1">
                {{ $t(translationPrefix + '.section6.title') }}
              </heading>

              <div class="is-size-5">
                <p>{{ $t(translationPrefix + '.section6.text') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="FreeNewsroomLanding2__section4 hero is-medium has-background-grey-lighter">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div
              v-for="n in [1, 2, 3, 4]"
              :key="n"
              class="column"
            >
              <div class="has-text-centered p-l-l p-r-l p-b-m">
                <img :src="$asset(`/images/landing2/step_${n}.png`)" alt="">
              </div>

              <heading size="5">
                {{ $t(`pages.free_newsroom2.section4.steps.${n}.title`) }}
              </heading>

              <p>{{ $t(`pages.free_newsroom2.section4.steps.${n}.content`) }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="FreeNewsroomLanding2__section5 hero has-background-black">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-10-tablet">
              <heading size="2" color="white">
                {{ $t(translationPrefix + '.section5.title') }}
              </heading>

              <sign-up-server-errors :server-errors="serverErrors" />

              <div v-if="!successful" class="form">
                <sign-up-form
                  v-model="formFields"
                  :submit-register="submitRegister"
                  :is-loading="isLoading"
                  :v="$v.formFields"
                  class="SignUpForm--withWhiteErrors"
                  is-horizontal
                />
              </div>

              <sign-up-success-message v-if="successful" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FreeNewsroomLandingBase from './FreeNewsroomLandingBase'

/**
 * FreeNewsroomLanding2 page. Used mostly for marketing campaigns.
 * Represents two identical pages, only texts are different. {@see module:FreeNewsroomLanding#translationPrefix}
 * @module FreeNewsroomLanding2
 */
export default {
  name: 'FreeNewsroomLanding2',

  extends: FreeNewsroomLandingBase,

  props: {
    /**
     * Passed from the route definition
     * Used only on {@see module:FreeNewsroomLanding2#gaEventName}
     */
    gaEvent: {
      type: String,
      required: true
    },

    /**
     * Passed from the route definition
     * Used only on {@see module:FreeNewsroomLanding2#translationPrefix}
     */
    transPrefix: {
      type: String,
      required: true
    }
  },

  computed: {
    /**
     * Overrides the default gaEventName from {@see module:RegisterBase#computed.gaEventName}
     */
    gaEventName () {
      return this.gaEvent
    },

    /**
     * Allows this page to have several text variants.
     * @returns {string}
     */
    translationPrefix () {
      return `pages.${this.transPrefix}`
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.FreeNewsroomLanding2 {
  $color_red: $hf__color-primary;
  $color_red_transparent: transparentize($color_red, .1);
  $color_black_transparent: transparentize($hf__color-black, .3);

  &__section1 {
    background: linear-gradient($color_black_transparent, $color_black_transparent), url($assetsPath + '/images/landing2/woman-tablet.jpg') no-repeat center/cover;
  }

  &__section3 {
    background-image: url($assetsPath + '/images/landing2/landingpage-cards.jpg');
  }

  .successful_message {
    border-top: 3px solid $success;
    background: $body-background-color;
    padding: 1rem;
    color: $text;
  }
}
</style>
